const welcomeActions = document.getElementById("welcome-actions")



function setPlayerCount(number) {
	number = Math.abs(parseInt(Number(number)))
	if (!Number.isSafeInteger(number)) number = 0;
  
	let { playerCount } = queryChildren(welcomeActions, { playerCount: "#bmod-home-player-count" });
  
	if (!number) {
	  if (playerCount instanceof HTMLElement) {
		playerCount.remove();
	  }
	  return;
	}
  
	playerCount = queryChildren(playerCount || createElement(
	  `<div id="bmod-home-player-count">
		<div class="ticker">
		  <p>Players</p>
		  <img>
		</div>
	  </div>`
	), {
	  ticker: ".ticker",
	  images: "img",
	});
  
	playerCount.dataset.players = number;
  
	const { ticker } = playerCount;
  
	const images = playerCount.images,
	  digits = String(number),
	  imgCount = images&&images.length===undefined?1:images.length
  
	for (let i = 0; i < Math.max(imgCount, digits.length); i++) {
	  const digit = digits[i];
	  let img = images[i];
  
	  if (typeof digit !== "string" && img && img.parentElement) {
		img.remove();
	  } else {
		setParent(applyAttributes(img || new Image(), {
		  src: `https://www.teamfortress.com/images/history/ticker_numerals/n${digit}.png`,
		  alt: digit
		}), ticker);
	  }
	}
  
	welcomeActions.prepend(playerCount);
  }

function getServersURL() {
	const {playButton,serversButton} = queryChildren(welcomeActions,{
		playButton:"#bmod-home-play-btn",
		serversButton:"#bmod-home-servers-btn"
	})

	return serversButton?.href
		|| playButton?.href
		|| "https://teamwork.tf/community/customserverlist/51"
}


function createPlayContainer() {
	const {playButton} = queryChildren(welcomeActions,{
		playButton:"#bmod-home-play-btn",
		playContainer:"#bmod-home-play"
	})

	if(!welcomeActions.playContainer) {
		const playContainer = queryChildren(`
            <div id="bmod-home-play" class="hide-mobile">
			<button class="btn-green" data-toggle-selector="#bmod-home-servers" data-toggle-class="hide">🞃</button>
			<ul id="bmod-home-servers" class="hide"></ul>
            </div>
        `,{
			serverList:"#bmod-home-servers"
		})
		playContainer.serverList.append(...Array.from({ length: 3 }, () => createItemCard({
					image: null,
					name: null,
					tags: null,
					number: null,
					url:"steam://"
				})),
				createLink(getServersURL(), { id: "bmod-home-servers-btn", class: "btn" },
				createElement(`<i class="mdi mdi-server"></i>`)," Servers"))

		playButton.replaceWith(playContainer)
		playContainer.prepend(playButton)

	}

	
}


function setPlayIP(ip) {
	const playButton = welcomeActions.querySelector("#bmod-home-play-btn"),
		icon = playButton.querySelector("i");
	playButton.href = ip ? `steam://connect/${ip}` : getServersURL();
	icon.classList.toggle("mdi-server", !ip);
	icon.classList.toggle("mdi-steam", ip);
	playButton.textContent = ip ? " Play" : " Servers";
	playButton.prepend(icon);
	return playButton;
}


function setServerList(servers) {
	if (!servers || !servers.length) servers = [];
	servers.length = 3;
	const serverList = welcomeActions.querySelector("#bmod-home-servers");
	console.debug({ servers, serverList });
	Array.from(serverList.querySelectorAll("[href^=steam]"), (serverLink, i) => {
		const { imageElement, nameElement, tagsContainer, numberElement } = serverLink.itemCard;
		const { ip, players, map, shortmode } = servers[i] || {};
		console.debug([
			imageElement,
			{ ip, serverLink },
			{ map, nameElement },
			{ shortmode, tagsContainer },
			{ players, numberElement }
		]);
		serverLink.href = `steam://connect/${ip}`;
		nameElement.textContent = map;
		tagsContainer.textContent = shortmode;
		numberElement.textContent = players;
	})
}

function updateServers(servers) {
	let {playContainer} = queryChildren(welcomeActions,{
		playContainer:"#bmod-home-play",
		playerCount: "#bmod-home-player-count"
	})
	const playerCount = welcomeActions.playerCount?.dataset?.players || 0
	if (!servers || !servers.length||!playerCount) servers = [];
	const playButton = setPlayIP(servers[0]?.ip)
	if (!playContainer && playerCount/*&&servers&&servers.filter(s=>s.players).length>1*/) {
		createPlayContainer();
	} else if (!playerCount && playContainer) {
		playContainer.replaceWith(playButton)
	}
	playContainer = welcomeActions.querySelector("#bmod-home-play")
	if (welcomeActions.querySelector("#bmod-home-play")) setServerList(servers)
}

//createPlayContainer();
//setPlayIP("localhost:1233")

//setServerList(randomServers(3))

// socket.emit("player-count")


fetch("/api/v2/player-count").then(stream=>stream.json()).then(({response,error})=>{
	if(error){
		console.error(error);
	}else  {
		setPlayerCount(response)
		fetch("/api/v2/popular-servers").then(stream=>stream.json()).then(({response,error})=>{
			if(error){
				console.error(error);
			}else {
				updateServers(response)
			}
		})
	}
})

// socket.on("player-count", count => {
// 	console.debug("player-count", count)
// 	setPlayerCount(count)
// 	socket.emit("servers")
// })
// socket.on("servers", servers => {
// 	console.debug("servers", servers)
// 	updateServers(servers)
// })